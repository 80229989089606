export default {
  intro: {
    title: 'Блог SalesArea',
    subtitle:
      'Добро пожаловать в наш блог, где мы делимся полезными статьями и новыми решениями для автоматизации бизнеса на маркетплейсах. Здесь вы найдете всё, что нужно для того, чтобы упростить рутинные задачи и сосредоточиться на развитии вашего дела!',
  },
  banner: {
    title: 'Селлер, тебе сюда!',
    subtitle: 'Подписывайся на наш Telegram и ВК — мы делимся инструментами автоматизации для прокачки твоего бизнеса на маркетплейсах',
  },
};
